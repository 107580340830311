// console.log('Site:utils');


/**
 * Get the window dimensions.
 *
 * @since 0.1.0
 *
 * @return object Dimensions with width and height.
 */
export function getWindowDimensions() {
  return {
    width  : window.innerWidth  || document.body.clientWidth,
    height : window.innerHeight || document.body.clientHeight,
  };
}


/**
 * Get the URL params.
 *
 * @since 0.1.0
 *
 * @return object URL params.
 */
export function getQueryVars() {
  const urlParams = {}
      , query     = window.location.search.substring(1)
      , vars      = query.split('&')
  ;

  let pair;

  for (let i = 0; i < vars.length; i++) {
    pair = vars[i].split('=');
    urlParams[pair[0]] = pair[1];
  }

  return urlParams;
}


/**
 * Converts an object into a query var string.
 *
 * @since 0.1.0
 *
 * @param object object URL params.
 *
 * @return string URL params.
 */
export function encodeAsQueryVars(object) {
  if (typeof object !== 'object') {
    return '';
  }

  return '?' + Object.keys(object).map(function (k) {
    return k + '=' + object[k];
  }).join('&');
}


/**
 * Get the width of the browser's scroll bar.
 *
 * @since 0.1.0
 *
 * @return int Scroll bar width.
 */
export function getScrollbarWidth() {
  const scrollElem = document.createElement('div');

  scrollElem.style.width    = 100;
  scrollElem.style.height   = 100;
  scrollElem.style.overflow = 'scroll';
  scrollElem.style.position = 'absolute';
  scrollElem.style.top      = -9999;

  document.body.appendChild(scrollElem);

  const scrollbarWidth = scrollElem.offsetWidth - scrollElem.clientWidth;

  document.body.removeChild(scrollElem);

  return scrollbarWidth;
}


/**
 * Rounds a number to the specified precision.
 *
 * From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
 *
 * @since 0.1.0
 *
 * @param  float number    Number to round.
 * @param  float precision Precision to round to. A positive 1 would round to 1 decimal place and -1 would round to the tens.
 *
 * @return float Rounded number.
 */
export function round(number, precision) {
  const factor            = Math.pow(10, precision)
      , tempNumber        = number * factor
      , roundedTempNumber = Math.round(tempNumber)
  ;

  return roundedTempNumber / factor;
}


/**
 * Converts a value to a bool.
 *
 * Accepts true, 'true', any non-zero number, '1', 'y', 'yes' as true, everything else is false.
 * Case-insensitive.
 *
 * @since 0.1.0
 *
 * @param  string value Value to convert to bool.
 *
 * @return bool Converted value.
 */
export function parseBool(value) {
  if (typeof value === 'boolean') {
    return value;
  }

  if (typeof value === 'string') {
    const lowerValue = value.toLowerCase();

    return lowerValue === 'true' || lowerValue === '1' || lowerValue === 'y' || lowerValue === 'yes';
  }

  if (typeof value === 'number') {
    if (isNaN(value) || !isFinite(value)) {
      return false;
    }

    return value !== 0;
  }

  return false;
}


/**
 * Get a random number.
 *
 * @since 0.1.0
 *
 * @param {float} min Minimum number.
 * @param {float} max Maximum number.
 *
 * @return {float} Number.
 */
export function random(min, max) {
  return Math.random() * (max - min) + min;
}


/**
 * Get a random integer.
 *
 * @since 0.1.0
 *
 * @param {float} min Minimum number.
 * @param {float} max Maximum number.
 *
 * @return {int} Number.
 */
export function randomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min)) + min;
}


/**
 * Rounds an integer up to the nearest interval.
 *
 * @since 0.1.0
 *
 * @param {number}  number   Number.
 * @param {integer} interval Interval to round up to.
 *
 * @return {integer} Rounded number.
 */
export function roundUpTo(number, interval) {
  return Math.ceil(number / interval) * interval;
}


/**
 * Rounds an integer down to the nearest interval.
 *
 * @since 0.1.0
 *
 * @param {number}  number   Number.
 * @param {integer} interval Interval to round down to.
 *
 * @return {integer} Rounded number.
 */
export function roundDownTo(number, interval) {
  return Math.floor(number / interval) * interval;
}


/**
 * Scales a value between number ranges.
 *
 * @since 0.1.0
 */
export function scaleBetween(unscaledNum, minAllowed, maxAllowed, min, max) {
  return (maxAllowed - minAllowed) * (unscaledNum - min) / (max - min) + minAllowed;
}


/**
 * HTML encodes a string.
 *
 * @since 0.1.0
 *
 * @param  string string String to encode.
 *
 * @return string Encoded string.
 */
export function htmlEncode(string) {
  return jQuery('<span>').text(string).html();
}


let uniqueIdIndex = 0;

/**
 * Get a number that increments each time the function is called.
 *
 * @since 0.1.0
 *
 * @return int Index.
 */
export function getUniqueIndex() {
  return uniqueIdIndex++;
}


/**
 * Escapes the special characters in the string so it can be used as part of a regexp.
 *
 * @since 0.1.0
 *
 * @param string text Text.
 *
 * @return string Text.
 */
export function escapeRegex(string) {
  return string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
