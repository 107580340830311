/**
 * Cards Block
 *
 * @since 0.1.0
 */

import {isBelowMedium} from '../responsive.js';
import {getUniqueIndex} from '../utils.js';

// console.log('Site:blocks:cards');

// The cards turn into accordion at mobile size if a heading tag (h2/3) is the first element in its contents

let currentSize = '';

function update() {
  if (isBelowMedium()) {
    setMode('mobile');
  } else {
    setMode('desktop');
  }
}


// Setup/teardown the accordion
function setMode(size) {
  // console.log('Site:blocks:cards:setMode', size, currentSize);

  if (currentSize === size) {
    return;
  }

  currentSize = size;

  const blocks = document.querySelectorAll('section.block-cards');

  for (const block of blocks) {
    // console.log('block', block);

    if (size === 'mobile') {
      setupAccordion(block);
      document.addEventListener('click', onClick);
    } else {
      document.removeEventListener('click', onClick);
      teardownAccordion(block);
    }
  }
}


function setupAccordion(element) {
  // console.log('Site:blocks:cards:setupAccordion', element);

  const cards = element.querySelectorAll('.inner .row .card');

  if (!cards.length) {
    return;
  }

  for (const card of cards) {
    setupCard(card);
  }

  element.classList.add('accordion');
  element.addEventListener('keydown', onKeyDown);
}


function teardownAccordion(element) {
  // console.log('Site:blocks:cards:teardownAccordion', element);

  const cards = element.querySelectorAll('.inner .row .card');

  if (!cards.length) {
    return;
  }

  element.removeEventListener('keydown', onKeyDown);

  for (const card of cards) {
    teardownCard(card);
  }

  element.classList.remove('accordion');
}


function setupCard(element) {
  // console.log('Site:blocks:cards:setupCard', element);

  // Create a title button from the existing heading, add it before the contents, hide the original title
  const contents = element.querySelectorAll('div.editor');

  if (!contents.length || !contents[0].children.length) {
    return;
  }

  const heading = contents[0].children[0];

  if (!(heading.tagName === 'H2' || heading.tagName === 'H3')) {
    return;
  }

  const uniqueId = 'block-card-accordion-' + getUniqueIndex();

  contents[0].id = uniqueId;
  contents[0].style.display = 'none';

  heading.style.display = 'none';

  const titleHeading = document.createElement(heading.tagName);
  const button = document.createElement('button');

  button.setAttribute('type', 'button');
  button.setAttribute('aria-expanded', false);
  button.setAttribute('aria-controls', uniqueId);
  button.innerText = heading.innerText;

  titleHeading.classList.add('title');
  titleHeading.appendChild(button);
  element.prepend(titleHeading);
}


function teardownCard(element) {
  // console.log('Site:blocks:cards:teardownCard', element);

  const button = element.querySelectorAll('h2.title,h3.title');

  if (button.length) {
    element.removeChild(button[0]);
  }

  const contents = element.querySelectorAll('div.editor');

  if (!contents.length || !contents[0].children.length) {
    return;
  }

  contents[0].style.display = '';

  const heading = contents[0].children[0];

  if (!(heading.tagName === 'H2' || heading.tagName === 'H3')) {
    return;
  }

  heading.style.display = '';
}


function onClick(event) {
  // console.log('Site:blocks:cards:event', event);

  const element = event.target;

  if (element.tagName !== 'BUTTON' || !element.parentElement.classList.contains('title')) {
    return;
  }

  if (!element.closest('section.block-cards')) {
    return;
  }

  const expanded = element.getAttribute('aria-expanded') === 'true';

  element.setAttribute('aria-expanded', expanded ? 'false' : 'true');
  element.parentElement.parentElement.querySelector('div.editor').style.display = expanded ? 'none' : '';
}


// Original code from W3 example https://www.w3.org/TR/wai-aria-practices/examples/accordion/accordion.html
function onKeyDown(event) {
  // console.log('Site:blocks:cards:onKeyDown', event);

  const key = event.which.toString();

  // 33 = Page Up, 34 = Page Down
  const ctrlModifier = (event.ctrlKey && key.match(/33|34/));

  // Is this coming from an accordion header?
  if (event.currentTarget.classList.contains('block-cards')) {
    const triggers = [...event.currentTarget.querySelectorAll('h3.title button')]; // Convert NodeList to array

    // Up/ Down arrow and Control + Page Up/ Page Down keyboard operations
    // 38 = Up, 40 = Down
    if (key.match(/38|40/) || ctrlModifier) {
      const index = triggers.indexOf(event.target);
      const direction = (key.match(/34|40/)) ? 1 : -1;
      const length = triggers.length;
      const newIndex = (index + length + direction) % length;

      triggers[newIndex].focus();
      event.preventDefault();
    } else if (key.match(/35|36/)) {
      // 35 = End, 36 = Home keyboard operations
      switch (key) {
        // Go to first accordion
        case '36':
          triggers[0].focus();
          break;
          // Go to last accordion
        case '35':
          triggers[triggers.length - 1].focus();
          break;
      }

      event.preventDefault();
    }
  }
}


function onBreakpointChange(event) {
  // console.log('Site:blocks:cards:onBreakpointChange', event);

  update();
}


window.addEventListener('breakpointchange', onBreakpointChange);

update();
