/**
 * Sets up breakpoint and resize listeners which are emitted as events on window.
 *
 * Events:
 *   breakpointchange  Sent when the window size changes between any of the breakpoints.
 *
 * @since 0.1.0
 */

// console.log('Site:responsive');

// https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
// These are not ranges, the is<Size> methods only return whether or not the window is above/below the breakpoint
const breakpoints = {
  small : {
    width      : 570,
    mediaMatch : null,
  },
  medium : {
    width      : 910,
    mediaMatch : null,
  },
  large : {
    width      : 1200,
    mediaMatch : null,
  },
};


// Setup listeners for when the browser width passes the breakpoints
Object.entries(breakpoints).forEach(([key, value]) => {
  const mediaMatch = window.matchMedia('only screen and (max-width: ' + (value.width - 1) + 'px)');

  mediaMatch.addListener(onMatchMediaChange);
  mediaMatch.breakpointName = key;

  breakpoints[key].mediaMatch = mediaMatch;
});


/**
 * Called whenever the mobileMatchMedia condition changes state.
 *
 * Fast and superior to watching resize and comparing window width to a breakpoint.
 * Sends an event when the state changes so other scripts can easily listen for mobile/desktop changes.
 * Adds the custom event properties:
 *   breakpointName string Name of the breakpoint that was crossed.
 *   isBelow        bool   Whether the window size is below the breakpoint.
 *
 * @since 0.1.0
 *
 * @param  object event Event.
 */
function onMatchMediaChange(event) {
  // console.log('Site:responsive:onMatchMediaChange');
  // console.log('event', event);
  // console.log(this);
  // console.log(isBelowSmall(), isBelowMedium(), isBelowLarge(), event.target.breakpointName, isBelow(event.target.breakpointName));

  // The breakpointName property is located in a different position in IE vs everyone else
  let breakpointName = '';

  if ('breakpointName' in event) {
    breakpointName = event.breakpointName;
  } else {
    breakpointName = event.target.breakpointName;
  }

  // Old way that works in IE
  const breakpointEvent = document.createEvent('Event');

  breakpointEvent.initEvent('breakpointchange', true, true);
  breakpointEvent.breakpointName = breakpointName;
  breakpointEvent.isBelow = isBelow(breakpointName);

  window.dispatchEvent(breakpointEvent);
}


/**
 * Returns true if the screen is less than the specified breakpoint.
 *
 * Check separately if you need to verify the device has a touchscreen, eg ('ontouchstart' in window)
 *
 * @since 0.1.0
 *
 * @param string size Breakpoint name.
 */
function isBelow(size) {
  // console.log('Site:response:is');

  if (!breakpoints[size]) {
    console.error('Unknown breakpoint', size);

    return false;
  }

  return breakpoints[size].mediaMatch.matches;
}


/**
 * Returns whether the screen is less than the 'small' breakpoint.
 *
 * Check separately if you need to verify the device has a touchscreen, eg ('ontouchstart' in window)
 *
 * @since 0.1.0
 */
export function isBelowSmall() {
  // console.log('Site:response:isSmall');

  return breakpoints.small.mediaMatch.matches;
}


/**
 * Returns whether the screen is less than the 'medium' breakpoint.
 *
 * Check separately if you need to verify the device has a touchscreen, eg ('ontouchstart' in window)
 *
 * @since 0.1.0
 */
export function isBelowMedium() {
  // console.log('Site:response:isSmall');

  return breakpoints.medium.mediaMatch.matches;
}


/**
 * Returns whether the screen is less than the 'large' breakpoint.
 *
 * Check separately if you need to verify the device has a touchscreen, eg ('ontouchstart' in window)
 *
 * @since 0.1.0
 */
export function isBelowLarge() {
  // console.log('Site:response:isSmall');

  return breakpoints.large.mediaMatch.matches;
}
