/**
 * Menus
 *
 * @since 0.1.0
 */

// console.log('Site:menus');

setupNavDropdowns(document.getElementById('menu-primary'));
setupNavDropdowns(document.getElementById('menu-primary-mobile'));
setupMobileNav();


function setupNavDropdowns(element) {
  const navItemsWithChildren = element.querySelectorAll('.menu-item-has-children > a');

  for (let i = 0; i < navItemsWithChildren.length; i++) {
    navItemsWithChildren[i].addEventListener('click', (e) => {
      const isActive = e.target.parentNode.classList.contains('dropdown-active');

      for (let j = 0; j < navItemsWithChildren.length; j++) {
        navItemsWithChildren[j].parentNode.classList.remove('dropdown-active');
      }

      if (!isActive) {
        e.target.parentNode.classList.add('dropdown-active');
      }

      e.preventDefault();
    });
  }
}


function setupMobileNav() {
  const mobileMenuTrigger = document.getElementById('mobile-menu-trigger');
  const mobileMenuClose = document.getElementById('mobile-menu-close');
  const mobileMenu = document.getElementById('mobile-menu');

  if (!mobileMenuTrigger || !mobileMenuClose || !mobileMenu) {
    return false;
  }

  mobileMenuTrigger.addEventListener('click', (e) => {
    mobileMenu.classList.add('active');
    e.preventDefault();
  });

  mobileMenuClose.addEventListener('click', (e) => {
    mobileMenu.classList.remove('active');
    e.preventDefault();
  });
}
